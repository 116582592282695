import React, { useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  control_spacing: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(1.5)
  },
  div: {
    paddingTop: 20
  }
}))

const ColoredLine = ({ color }) => (
  <span
    style={{
      display: 'inline-block',
      width: '20px',
      height: '2px',
      backgroundColor: color,
      marginLeft: '8px',
      verticalAlign: 'middle',
    }}
  />
);

const ColoredDot = ({ color }) => (
  <span
    style = {{
      display: 'inline-block',
      width: '8px',
      height: '8px',
      backgroundColor: color,
      marginLeft: '8px',
      border: '1px solid #B9B4B3',
      verticalAlign: 'middle',
      borderRadius: '50%',
    }}
  />
)

export default function OverlayMenu ({overlay, setOverlay}) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleChange = (event) => {
    setOverlay({...overlay, [event.target.name] : !overlay[event.target.name]})
  }

  return (
    <div className = {classes.div}>
      <Button variant = 'contained' color = 'primary' onClick = {openMenu} endIcon = {<ArrowDropDownIcon/>}>
        Overlays
      </Button>
      <Popover
        open = {open}
        anchorEl = {anchorEl}
        onClose = {handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid>
          <Grid item className = {classes.control_spacing}>
            <FormControl>
              <FormLabel>
                Overlays
              </FormLabel>
              <FormGroup column>
                <FormControlLabel
                  name = 'crude'
                  control = {<Checkbox color = 'primary' checked = {overlay.crude} size = 'small'/>}
                  label = {
                    <>
                      Crude Oil Pipelines
                      <ColoredLine color = '#8B0000'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'refined'
                  control = {<Checkbox color = 'primary' checked = {overlay.refined} size = 'small'/>}
                  label = {
                    <>
                      Petroleum Product Pipelines
                      <ColoredLine color = '#00008B'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'padd'
                  control = {<Checkbox color = 'primary' checked = {overlay.padd} size = 'small'/>}
                  label = {
                    <>
                      Petroleum Administration for Defense Districts (PADD)
                      <ColoredLine color = '#B9B4B3'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'product'
                  control = {<Checkbox color = 'primary' checked = {overlay.product} size = 'small'/>}
                  label = {
                    <>
                      Petroleum Product Terminals
                      <ColoredDot color = '#32CD32'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'intermodal'
                  control = {<Checkbox color = 'primary' checked = {overlay.intermodal} size = 'small'/>}
                  label = {
                    <>
                      Intermodal Freight Facilities Pipeline Terminals
                      <ColoredDot color = '#FFA500'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'bnsf'
                  control = {<Checkbox color = 'primary' checked = {overlay.bnsf} size = 'small'/>}
                  label = {
                    <>
                    North American Rail Network Lines -BNSF
                      <ColoredLine color = '#2F4F4F'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'csx'
                  control = {<Checkbox color = 'primary' checked = {overlay.csx} size = 'small'/>}
                  label = {
                    <>
                      North American Rail Network Lines -CSX
                      <ColoredLine color = '#006400'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'up'
                  control = {<Checkbox color = 'primary' checked = {overlay.up} size = 'small'/>}
                  label = {
                    <>
                      North American Rail Network Lines -UP
                      <ColoredLine color = '#000000'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'ns'
                  control = {<Checkbox color = 'primary' checked = {overlay.ns} size = 'small'/>}
                  label = {
                    <>
                      North American Rail Network Lines -NS
                      <ColoredLine color = '#8B4513'/>
                    </>
                  }
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'marine'
                  control = {<Checkbox color = 'primary' checked = {overlay.marine} size = 'small'/>}
                  label = {
                    <>
                      Marine Highways
                      <ColoredLine color = '#456898'/>
                    </>
                  }
                  onChange = {handleChange}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </div>
  )
}
